<template>
  <div class="d-flex justify-end">
    <span class="blue--text text--darken-4"><b>{{userName}}</b></span>
    <v-menu offset-y nudge-bottom="14" min-width="230" left class="">
      <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">{{ icons.mdiMenuDown }}</v-icon>
      </template>
      <v-list>
        <!-- Logout -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">{{ icons.mdiLogoutVariant }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-menu>
  </div>
</template>

<script>
import { mdiLogoutVariant,mdiMenuDown } from '@mdi/js'

import { mapActions, mapState } from "vuex";

export default {
  setup() {
    return {
      icons: { mdiLogoutVariant,mdiMenuDown },
    }
  },
  computed: {
    ...mapState({
        userType: (state) => state.auth.userType,
        userName: (state) => state.auth.userName,
    }),
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
