export default [{
   path: '/',
   component: () => import('@/views/container/PageContainer'),
   meta: {
      title: 'Home',
   },
   children: [{
         path: '',
         redirect: 'member',
      },
      {
         path: 'member',
         component: () => import('@/views/container/Container'),
         children: [
            {
               path: '',
               name: 'member',
               component: () => import('@/views/Member.vue'),
            },
            {
               path: 'detail/:id',
               name: 'memberDetail',
               component: () => import('@/views/MemberDetail.vue'),
            }
         ]
      },
      {
         path: 'voucher',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'package',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'voucherPackage',
                     component: () => import('@/views/VoucherPackage.vue'),
                  },
                  {
                     path: 'create',
                     name: 'voucherPackageCreate',
                     component: () => import('@/views/VoucherPackageDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'voucherPackageDetail',
                     component: () => import('@/views/VoucherPackageDetail.vue'),
                  }
               ]
            },
            {
               path: 'type',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'voucherType',
                     component: () => import('@/views/VoucherType.vue'),
                  },
                  {
                     path: 'create',
                     name: 'voucherTypeCreate',
                     component: () => import('@/views/VoucherTypeDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'voucherTypeDetail',
                     component: () => import('@/views/VoucherTypeDetail.vue'),
                  }
               ]
            },
            {
               path: 'category',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'voucherCategory',
                     component: () => import('@/views/VoucherCategory.vue'),
                  },
                  {
                     path: 'create',
                     name: 'voucherCategoryCreate',
                     component: () => import('@/views/VoucherCategoryDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'voucherCategoryDetail',
                     component: () => import('@/views/VoucherCategoryDetail.vue'),
                  }
               ]
            },
         ],
         
      },
      {
         path: 'redeem',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'record',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'redeemRecord',
                     component: () => import('@/views/RedeemRecord.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'redeemRecordDetail',
                     component: () => import('@/views/RedeemRecordDetail.vue'),
                  }
               ]
            },
            {
               path: 'item',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'redeemItem',
                     component: () => import('@/views/RedeemItem.vue'),
                  },
                  {
                     path: 'create',
                     name: 'redeemItemCreate',
                     component: () => import('@/views/RedeemItemDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'redeemItemDetail',
                     component: () => import('@/views/RedeemItemDetail.vue'),
                  }
               ]
            },
            {
               path: 'category',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'redeemCategory',
                     component: () => import('@/views/RedeemCategory.vue'),
                  },
                  {
                     path: 'create',
                     name: 'redeemCategoryCreate',
                     component: () => import('@/views/RedeemCategoryDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'redeemCategoryDetail',
                     component: () => import('@/views/RedeemCategoryDetail.vue'),
                  }
               ]
            },
         ]
      },
      {
         path: 'trade-in',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'record',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'tradeInRecord',
                     component: () => import('@/views/TradeInRecord.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'tradeInRecordDetail',
                     component: () => import('@/views/TradeInRecordDetail.vue'),
                  }
               ]
            },
            {
               path: 'category',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'tradeInCategory',
                     component: () => import('@/views/TradeInCategory.vue'),
                  },
                  {
                     path: 'create',
                     name: 'tradeInCategoryCreate',
                     component: () => import('@/views/TradeInCategoryDetail.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'tradeInCategoryDetail',
                     component: () => import('@/views/TradeInCategoryDetail.vue'),
                  }
               ]
            },
            {
               path: 'summary',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'tradeInSummary',
                     component: () => import('@/views/TradeInSummary.vue'),
                  }
               ]
            },
         ]
      },
      {
         path: 'staff-credit',
         component: () => import('@/views/container/Container'),
         children: [
            {
               path: '',
               name: 'staffCreditRedeem',
               component: () => import('@/views/StaffCreditRedeem.vue'),
            },
            {
               path: 'create',
               name: 'staffCreditRedeemCreate',
               component: () => import('@/views/StaffCreditRedeemDetail.vue'),
            },
            {
               path: 'detail/:id',
               name: 'staffCreditRedeemDetail',
               component: () => import('@/views/StaffCreditRedeemDetail.vue'),
            }
         ]
      },
      {
         path: 'job',
         component: () => import('@/views/container/Container'),
         children: [
            {
               path: 'mgmt',
               component: () => import('@/views/container/GroupContainer'),
               children: [
                  {
                     path: '',
                     name: 'jobManagement',
                     component: () => import('@/views/JobManagement.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'jobManagementDetail',
                     component: () => import('@/views/JobManagementDetail.vue'),
                  }
               ]
            },
         ]
      },
      {
         path: 'order',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'mgmt',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'orderManagement',
                     component: () => import('@/views/OrderManagement.vue'),
                  },
                  {
                     path: 'detail/:id',
                     name: 'orderDetail',
                     component: () => import('@/views/OrderManagementDetail.vue'),
                  }
               ]
            }
         ]
      },
      {
         path: 'questionnaire',
         component: () => import('@/views/container/Container'),
         children: [
            {
               path: 'record',
               name: 'questionnaireRecord',
               component: () => import('@/views/QuestionnaireRecord.vue'),
            },
            {
               path: 'setting',
               name: 'questionnaireSetting',
               component: () => import('@/views/QuestionnaireSetting.vue'),
            }
         ]
      },
      {
         path: 'report',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'report',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'report',
                     component: () => import('@/views/Report.vue'),
                  },
               ]
            },
         ]
      },
      {
         path: 'push-noti',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'noti',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'CustomNoti',
                     component: () => import('@/views/CustomNoti.vue'),
                  },
               ]
            },
         ]
      },
      {
         path: 'banner',
         component: () => import('@/views/container/GroupContainer'),
         children: [
            {
               path: 'mgmt',
               component: () => import('@/views/container/Container'),
               children: [
                  {
                     path: '',
                     name: 'bannerManagement',
                     component: () => import('@/views/BannerManagement.vue'),
                  },
               ]
            },
         ]
      },
      {
         path: 'others',
         component: () => import('@/views/container/Container'),
         children: [
            {
               path: '',
               name: 'others',
               component: () => import('@/views/Others.vue'),
            },
         ]
      },
   ],
}, ]
