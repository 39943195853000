import Vue from 'vue';

const UPDATING = 'UPDATING';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const UPDATE_FAILED = 'UPDATE_FAILED';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export default {
   state: {
      isFetching: false,
      isSuccess: false,
      isFail: false,
      errorMessage: null
   },
   actions: {
      uploadImage: async ({ commit }, request) => {
         commit(UPDATING);
         request = {
            type: 'upload_image',
            data: { forceJPG: true },
            ...request,
         }
         console.log(`[${request.type}]`, 'request', request)
         try {
            commit(UPDATE_SUCCESS);
            let { data: [result, data] } = await Vue.prototype.$request(request);
            if (!result) {
               throw new Error('upload image fail')
            }
            commit(UPDATE_SUCCESS);
            data = {
               ...data,
               url: Vue.prototype.$getMediaUrl(data.filename),
            }
            console.log(`[${request.type}]`, 'return', data)
            return data;
         } catch(err) {
            console.log('err', err)
            commit(UPDATE_FAILED);
            commit(SET_ERROR_MESSAGE, err.message);
            return
         }
      },
      sendRequest: async ({ commit }, request) => {
         const { noStatusSet = false } = request?.options ?? {}
         if (!noStatusSet) commit(UPDATING);
         console.log(`[${request.type}]`, 'request', request)
         if (!request.type) {
            return new Promise((resolve, reject) => {
               setTimeout(() => {
                  console.log('test');
                  if (!noStatusSet) commit(UPDATE_SUCCESS);
                  // resolve({ error: 'test' });
                  resolve({ data: 'test' });
               }, 1000);
             });
         } else {
            try {
               const resp = await Vue.prototype.$request(request);
               if (resp.data.result === 'success') {
                  if (!noStatusSet) commit(UPDATE_SUCCESS);
                  console.log(`[${request.type}]`, 'return', resp.data)
                  return resp.data
               } else if (resp.data.result === 'fail') {
                  throw new Error(resp.data.data)
               } else {
                  throw new Error('unknown fail')
               }
            } catch(err) {
               console.log('err', err.message)
               if (!noStatusSet) commit(UPDATE_FAILED);
               if (!noStatusSet) commit(SET_ERROR_MESSAGE, err.message);
               return
               // const { errorCode } = err.response.data;
               // switch(errorCode) {
               //    case 403:
               //       localStorage.removeItem('adminToken');
               //       router.push('/login');
               //    default:
               //       commit(SET_ERROR_MESSAGE, err.response.data);
               //       console.groupEnd()
               //       return err.response;
               // }
            }
         }
      },
      showLoader: async ({ commit }, show) => {
         show ? commit(UPDATING) : commit(UPDATE_SUCCESS);
      },
      setErrorMessage: ({commit}, message) => {
         commit(SET_ERROR_MESSAGE, message);
      }
   },
   mutations: {
      [UPDATING]: (state) => {
			state.isFetching = true;
			state.isSuccess = false;
			state.isFail = false;
      },
		[UPDATE_SUCCESS]: (state) => {
			state.isFetching = false;
			state.isSuccess = true;
         state.isFail = false;
      },
		[UPDATE_FAILED]: (state) => {
			state.isFetching = false;
			state.isSuccess = false;
			state.isFail = true;
      },
		[SET_ERROR_MESSAGE]: (state, errMessage) => {
			state.errorMessage = errMessage;
      },
   }
}