export default {
  admin: [
    {
      title: 'Member',
      to: { name: 'member' },
    },
    {
      title: 'Voucher Management',
      path: 'voucher',
      children: [
        {
          title: 'Package',
          to: { name: 'voucherPackage' },
        },
        {
          title: 'Package category',
          to: { name: 'voucherCategory' },
        },
        {
          title: 'Service',
          to: { name: 'voucherType' },
        },
      ]
    },
    {
      title: 'Redeem management',
      path: 'redeem',
      children: [
        {
          title: 'Redeem Record',
          to: { name: 'redeemRecord' },
        },
        {
          title: 'Redeem item',
          to: { name: 'redeemItem' },
        },
        // {
        //   title: 'Redeem category',
        //   to: { name: 'redeemCategory' },
        // },
      ]
    },
    {
      title: 'Trade-in',
      path: 'trade-in',
      children: [
        {
          title: 'Trade-in record',
          to: { name: 'tradeInRecord' },
        },
        {
          title: 'Trade-in category',
          to: { name: 'tradeInCategory' },
        },
        {
          title: 'Summary',
          to: { name: 'tradeInSummary' },
        },
      ]
    },
    {
      title: 'Staff Credit Redeem',
      to: { name: 'staffCreditRedeem' },
    },
    {
      title: 'Job management',
      to: { name: 'jobManagement' },
    },
    // {
    //   title: 'Job management',
    //   path: 'job',
    //   children: [
    //     {
    //       title: 'Job management',
    //       to: { name: 'jobManagement' },
    //     },
    //   ]
    // },
    {
      title: 'Order management',
      to: { name: 'orderManagement' },
    },
    // {
    //   title: 'Order management',
    //   path: 'order',
    //   children: [
    //     {
    //       title: 'Order management',
    //       to: { name: 'orderManagement' },
    //     },
    //   ]
    // },
    {
      title: 'Questionnaire',
      path: 'questionnaire',
      children: [
        {
          title: 'Questionnaire record',
          to: { name: 'questionnaireRecord' },
        },
        {
          title: 'Questionnaire setting',
          to: { name: 'questionnaireSetting' },
        },
      ]
    },
    {
      title: 'Report',
      to: { name: 'report' },
    },
    // {
    //   title: 'Report',
    //   path: 'report',
    //   children: [
    //     {
    //       title: 'Report',
    //       to: { name: 'report' },
    //     },
    //   ]
    // },
    {
      title: 'Push notification',
      path: 'push-noti',
      children: [
        {
          title: 'Custom notification',
          to: { name: 'CustomNoti' },
        },
      ]
    },
    {
      title: 'Banner management',
      to: { name: 'bannerManagement' },
    },
    // {
    //   title: 'Banner management',
    //   path: 'banner',
    //   children: [
    //     {
    //       title: 'Banner management',
    //       to: { name: 'bannerManagement' },
    //     },
    //   ]
    // },
    {
      title: 'Others',
      to: { name: 'others' },
    },
  ],
}