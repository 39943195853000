import axios from 'axios'
import { serialize } from 'object-to-formdata'

const API_ENDPOINT = 'https://new-pcboy.innpressionhost.com/backend/develop/request.php'
const MEDIA_URI = 'https://new-pcboy.innpressionhost.com/media/'

const apiPlugin = {
    install: (Vue) => {
        Vue.prototype.API_ENDPOINT = API_ENDPOINT
        Vue.prototype.MEDIA_URI = MEDIA_URI
        Vue.prototype.$getMediaUrl = function(filename) {
            return MEDIA_URI + filename
        }
        Vue.prototype.$request = function({
            type,
            data,
            file,
            json = true,
        }) {
            const requestData = {
                url: API_ENDPOINT,
                method: 'POST',
                data: serialize({
                   [type]: json ? JSON.stringify(data) : data,
                   file,
                }),
            }
            return axios({ ...requestData, timeout: 30000 })
        }
    }
}

export default apiPlugin