import auth from './modules/auth';
import dialog from './modules/dialog';
import request from './modules/request';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
    auth,
    dialog,
    request
  },
})
